<template>
  <div id="app" class="construction-page">
    <img src="@/assets/logo.png" alt="Maramara Design Logo" class="logo" />
    <h1>Przygotowuje coś pięknego</h1>
    <p>Nowości na moim Instagramie.</p>
    <a href="https://www.instagram.com/maramaradesign/" target="_blank" class="instagram-link">
      <img src="@/assets/instagram.png" alt="Instagram Icon" />
      maramaradesign
    </a>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style scoped>
.construction-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  background-color: #f9f9f9;
  color: #333;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.logo {
  max-width: 200px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.instagram-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 1.2em;
}

.instagram-link img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
</style>